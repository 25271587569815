import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { TextField } from '../../../components/forms/textfield'
import { FormEvent, useState } from 'react'
import { FileUpload } from '../../../components/forms/file-upload'
import { createTruck } from '../../../api/truck'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'

export const CreateTruckPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [plateNumber, setPlateNumber] = useState('')
  const [username, setUsername] = useState('')
  const [scheduleNote, setScheduleNote] = useState('')
  const [photo, setPhoto] = useState(undefined)

  const initForm = () => {
    setName('')
    setPlateNumber('')
    setUsername('')
    setScheduleNote('')
    setPhoto(undefined)
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = new FormData()
    if (photo) payload.append('photo', photo[0])
    payload.append('name', name)
    payload.append('plateNo', plateNumber)
    payload.append('username', username)
    payload.append('scheduleNote', scheduleNote)

    createTruck(
      payload,
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully created a truck!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        initForm()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to create a truck. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        //   setErrMsg('Something went wrong. Please try again later.')
        setIsLoading(false)
      }
    )
  }

  return (
    <>
      <div className="p-4 w-full">
        <Link to="/admin/trucks">
          <button
            type="button"
            className="self-center bg-transparent hover:bg-gray-200 active:bg-gray-300 active:shadow-none text-gray-900 text-sm p-2 rounded-md inline-block"
          >
            <ArrowLeftIcon className="w-5 h-5 inline-block" />
            <span className="self-center inline-block ml-4">TRUCK LIST</span>
          </button>
        </Link>
        <div className="self-center p-4 text-2xl font-bold">Create Truck</div>
        <form
          className="px-4 grid gap-4 w-1/2"
          onSubmit={(e: FormEvent) => submitForm(e)}
        >
          <TextField
            handleChange={(q: string) => setUsername(q)}
            value={username}
            disabled={isLoading}
            label="Truck Code"
            required
          />
          <TextField
            handleChange={(q: string) => setName(q)}
            value={name}
            disabled={isLoading}
            label="Name"
            required
          />
          <TextField
            handleChange={(q: string) => setPlateNumber(q)}
            value={plateNumber}
            disabled={isLoading}
            label="Plate Number"
            required
          />
          <TextField
            handleChange={(q: string) => setScheduleNote(q)}
            value={scheduleNote}
            disabled={isLoading}
            label="Schedule Note"
            required
          />
          <FileUpload
            handleChange={setPhoto}
            value={photo}
            label="Upload image"
            disabled={isLoading}
            required
          />
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`block text-sm font-semibold p-3 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
              }  text-slate-50 mt-4 w-full`}
            >
              {isLoading ? 'Please wait' : 'SUBMIT'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
