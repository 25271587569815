import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import PLACEHOLDER_IMG from './../../assets/images/placeholder.png'
import moment from 'moment'
import { useState } from 'react'

export type TruckT = {
  id: string
  name: string,
  imgSrc: string
  plateNo: string
  scheduleNote: string
  userId: string,
  createdAt: string,
  user: {
    username: string
  }
}

export type TruckCardProps = {
  truck: TruckT
}

export const TruckCard = ({ truck }: TruckCardProps) => {
  const [open, setOpen] = useState(false)
  const { name, imgSrc, plateNo, scheduleNote, user, createdAt } = truck

  return (
    <>
      <div
        className="bg-gray-50 p-4 w-full flex justify-between rounded-md cursor-pointer hover:bg-gray-200 border border-gray-300"
        onClick={() => setOpen(true)}
      >
        <span className="inline-block self-center">{name}</span>
        <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-40 opacity-80 "
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 min-h-screen z-50 bg-gray-50 p-10 flex flex-col duration-200 break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/3 shadow-none'
        }`}
      >
        <div className="text-right">
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          className="w-100 h-[250px] bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`
          }}
        ></div>
        <div className="text-left flex-row gap-4">
          <div className="mb-8">
            <div className="text-sm text-gray-600">TRUCK</div>
            <div className="text-3xl font-bold text-slate-900 break-words">
              {name}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600">TRUCK CODE</div>
            <div className="text-xl text-slate-900">{user.username? user.username:"-"}</div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600">PLATE NO.</div>
            <div className="text-xl text-slate-900">{plateNo}</div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600">SCHEDULE NOTE</div>
            <div className="text-xl text-slate-900">{scheduleNote}</div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600 pt-8">Created at {moment(createdAt).format('MMMM Do YYYY, hh:mm A')}</div>
          </div>
        </div>
      </div>
    </>
  )
}
