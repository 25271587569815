type TimePickerParams = {
    handleChange: (value: string) => void
    value?: string
    placeholder?: string
    label?: string
    required?: boolean
    disabled?: boolean
  }
  
  export const TimePicker = ({
    handleChange,
    value = '',
    placeholder = '',
    label = '',
    required = false,
    disabled = false
  }: TimePickerParams) => {
  
    const change = (c: any) => {
      handleChange(c.target.value)
    }
  
    return (
      <div className="py-2">
        {label && (
          <label className="block text-sm text-gray-700 mb-2">{label}</label>
        )}
        <input
          type="time"
          placeholder={placeholder}
          className="border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500"
          value={value}
          onChange={change}
          required={required}
          disabled={disabled}
        />
      </div>
    )
  }
  