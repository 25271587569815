import { CallBackError, CallBackResponse, createAxios } from './createAxios'

const api = createAxios()

export const getTrucks = async (
  query: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const url = `/trucks${query !== '' ? '?' + query : ''}`
  api
    .get(url, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const getTruckById = async (
  id: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const url = `/trucks/${id}?includeSchedule=todayAndUpcoming`
  api
    .get(url, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createTruck = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/trucks', payload, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createTruckSchedule = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/truck-schedules', payload, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

// export const getTruckSchedules = async (
//   cb: CallBackResponse,
//   errCb: CallBackError
// ) => {
//   api
//     .get(`/trucks`, {
//       headers: {
//         Authorization: `Bearer ${tokens.access.token}`
//       }
//     })
//     .then((response) => cb(response ?? []))
//     .catch((error) => errCb(error))
// }

export const getTruckScheduleBySchedId = async (
  id: string,
  query: string = "",
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .get(`/truck-schedules/${id}${query? `?${query}`:''}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const updateTruckScheduleBySchedId = async (
  id: string,
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .patch(`/truck-schedules/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const completeTruckScheduleRoutePoint = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post(`/completed-route-points`, payload, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const deleteTruckScheduleRoutePoint = async (
  truckScheduleId: string,
  routePointId: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .delete(`/completed-route-points?truckScheduleId=${truckScheduleId}&routePointId=${routePointId}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}