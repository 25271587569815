// import { TextField } from '../../../components/forms/textfield'
import { useEffect, useState } from 'react'
import { TruckCard } from '../../../components/cards/truck-card'
import { getTrucks } from '../../../api/truck'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const TrucksPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  // const [query, setQuery] = useState('')
  const [trucks, setTrucks] = useState([])
  const [isReady, setIsReady] = useState(false)

  // const handleOnChange = (q: string) => {
  //   setQuery(q)
  // }

  const refetch = (queryParam: string = '') => {
    setIsReady(false)
    try {
      getTrucks(
        queryParam,
        loggedInUser.tokens,
        (response) => {
          setTrucks(response.data)
          setIsReady(true)
        },
        (error) => {
          console.log(error)
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
      setIsReady(true)
    }
  }

  useEffect(() => {
    refetch()
  }, [loggedInUser])

  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex p-4 align-middle self-center">
          <span className="self-center text-2xl font-bold">Trucks</span>
          <Link to="/admin/trucks/create">
            <button
              type="button"
              className="self-center ml-4 bg-orange-500 hover:bg-orange-400 active:bg-orange-600 active:shadow-none text-white text-sm p-2 rounded-md shadow-md inline-block"
            >
              {/* <PlusIcon className="w-5 h-5 inline-block" /> */}
              <span className="self-center inline-block">CREATE</span>
            </button>
          </Link>
        </div>
        {/* <div className="self-center w-1/3 p-4">
          <TextField
            handleChange={(q: string) => handleOnChange(q)}
            value={query}
            placeholder="Find truck"
          />
        </div> */}
      </div>

      <div className="p-4 flex flex-col gap-2">
        {!isReady ? (
          <div>Loading ..</div>
        ) : trucks.length === 0 ? (
          <div>No records found</div>
        ) : (
          trucks.map((truck, i) => {
            return (
              <div key={i} className="flex">
                <span className="self-center inline-block w-[30px] text-sm">
                  {i + 1}
                </span>
                <TruckCard truck={truck} />
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
