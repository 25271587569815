import { FormEvent, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getRoutes } from '../../../api/routes'
import { DatePicker } from '../../../components/forms/date-picker'
import { ComboBox } from '../../../components/forms/combo-box'
import { createTruckSchedule, getTrucks } from '../../../api/truck'
import { WasteTypes } from '../../../constants/options'
import { getPersonnels } from '../../../api/personnel'
import { MultiPickerBox } from '../../../components/forms/multi-picker-box'
import { PersonnelT } from '../../../components/cards/personnel-card'
import { useSelector } from 'react-redux'

const wasteTypeOptions = WasteTypes

export const CreateSchedulePage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isLoading, setIsLoading] = useState(false)
  const [schedDate, setSchedDate] = useState('')
  const [truck, setTruck] = useState('')
  const [route, setRoute] = useState('')
  const [wasteType, setWasteType] = useState('')
  const [truckOptions, setTruckOptions] = useState([])
  const [routeOptions, setRouteOptions] = useState([])
  const [rawTruckOptions, setRawTruckOptions] = useState([])
  const [rawRouteOptions, setRawRouteOptions] = useState([])

  const [rawPersonnelsOptions, setRawPersonnelsOptions] = useState<
    PersonnelT[]
  >([])

  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([])
  const [driverOptions, setDriverOptions] = useState<string[]>([])

  const [collectorsOptions, setCollectorOptions] = useState<string[]>([])
  const [selectedCollectors, setSelectedCollectors] = useState<string[]>([])

  const [isPersonnelReady, setIsPersonnelReady] = useState(false)

  const initForm = () => {
    setTruck('')
    setRoute('')
    setSchedDate('')
    setWasteType('')
    setSelectedDrivers([])
    setSelectedCollectors([])
  }

  useEffect(() => {
    getTrucks(
      '',
      loggedInUser.tokens,
      (response) => {
        setRawTruckOptions(response.data)
        const t = response.data.map((truck: any) => {
          return truck.name
        })
        setTruckOptions(t)
      },
      (error) => {
        console.log(error)
      }
    )

    getRoutes(
      '',
      loggedInUser.tokens,
      (response) => {
        setRawRouteOptions(response.data)
        const r = response.data.map((route: any) => {
          return route.name
        })
        setRouteOptions(r)
      },
      (error) => {
        console.log(error)
      }
    )

    getPersonnels(
      '',
      loggedInUser.tokens,
      (response) => {
        setRawPersonnelsOptions(response.data)
        setDriverOptions(
          response.data
            .filter((p: any) => {
              return p.type === 'driver'
            })
            .map((d: any) => {
              return d.fullName
            })
        )
        setCollectorOptions(
          response.data
            .filter((p: any) => {
              return p.type === 'collector'
            })
            .map((d: any) => {
              return d.fullName
            })
        )

        setIsPersonnelReady(true)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [loggedInUser])

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    
    const selectedTruck: any = rawTruckOptions.filter(
      (t: any) => t.name === truck
    )[0]
    const selectedRoute: any = rawRouteOptions.filter(
      (r: any) => r.name === route
    )[0]
    
    const selectedPersonnels = [...selectedDrivers, ...selectedCollectors]

    const selectedPersonnelIds = rawPersonnelsOptions
      .filter((d: any) => {
        return selectedPersonnels.includes(d.fullName)
      })
      .map((dd: any) => {
        return dd.id
      })

    createTruckSchedule(
      {
        scheduledDate: schedDate,
        truckId: selectedTruck.id,
        routeId: selectedRoute.id,
        wasteType: wasteType,
        personnels: selectedPersonnelIds
      },
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully created a schedule!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        initForm()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to create a schedule. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        setIsLoading(false)
      }
    )
  }

  return (
    <>
      <div className="p-4 w-full">
        <div className="self-center p-4 text-2xl font-bold">
          Create Schedule
        </div>
        <form
          className="p-4 grid grid-cols-2 gap-8"
          onSubmit={(e: FormEvent) => submitForm(e)}
        >
          <div>
            <div className="text-sm font-bold mb-4">SCHEDULE INFO</div>
            <div className="grid gap-4">
              <DatePicker
                handleChange={(q: string) => setSchedDate(q)}
                value={schedDate}
                disabled={isLoading}
                label="Select Date"
                required
              />
              <div className="z-20">
                <ComboBox
                  label="Select Truck"
                  options={truckOptions}
                  placeholder="Select Truck"
                  handleChange={setTruck}
                  value={truck}
                  required
                />
              </div>
              <div className="z-10">
                <ComboBox
                  label="Select Route"
                  options={routeOptions}
                  placeholder="Select Route"
                  handleChange={setRoute}
                  value={route}
                  required
                />
              </div>
              <ComboBox
                label="Select Waste Type"
                options={wasteTypeOptions}
                placeholder="Select waste type"
                handleChange={setWasteType}
                value={wasteType}
                required
              />
            </div>
          </div>
          <div>
            <div className="text-sm font-bold mb-4">MANPOWER</div>
            {isPersonnelReady ? (
              <div className="grid gap-4">
                <div className='z-10'>
                  <MultiPickerBox
                    label="Drivers"
                    options={driverOptions}
                    placeholder="Select driver"
                    handleChange={(drivers: string[]) =>
                      setSelectedDrivers(drivers)
                    }
                    selected={selectedDrivers}
                    required={selectedDrivers.length === 0}
                  />
                </div>
                <MultiPickerBox
                  label="Collectors"
                  options={collectorsOptions}
                  placeholder="Select collectors"
                  handleChange={(collector: string[]) =>
                    setSelectedCollectors(collector)
                  }
                  selected={selectedCollectors}
                  required={selectedCollectors.length === 0}
                />
              </div>
            ) : (
              <div className="text-sm text-gray-500">Please wait</div>
            )}
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`block text-sm font-semibold p-3 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
              }  text-slate-50 mt-4 w-full`}
            >
              {isLoading ? 'Please wait' : 'SUBMIT'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
