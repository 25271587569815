import { FormEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { login } from '../../api/auth/login'
import KEY_PARTNERS from './../../assets/images/partners.png'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../store/loggedInUserReducer'


const APP_NAME = 'swtrack'
const APP_DESCRIPTION = 'Tracking Truck Collection & Routes'

export const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    if (loggedInUser) {
      const role = loggedInUser.user.role
      // redirect to default dashboard of the user's role
      if (role === 'truck') {
        navigate('/')
      } else {
        navigate(`/${role}`)
      }
    }
  }, [loggedInUser])

  const submitForm = (e: FormEvent) => {
    e.preventDefault()

    setErrMsg('')
    setIsLoading(true)

    login(
      {
        username,
        password
      },
      (response) => {
        if (response.status === 200) {
          dispatch(setUser({
            ...response.data
          }))
        }
      },
      (error) => {
        console.log(error)
        setErrMsg(error.response.data.message)
        setIsLoading(false)
      }
    )
  }

  const inputLabelStyle = 'text-slate-500 text-sm block my-2'
  const inputStyle = `bg-slate-white rounded text-md w-full mb-2 p-2 border border-slate-400 ${
    !isLoading && 'hover:border-slate-600 focus:border-slate-800'
  }`

  return (
    <div className="flex flex-col justify-center min-h-screen py-8 items-center">
      <div className="bg-slate-50 shadow-md p-8 rounded-md w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
        <Link to="/" className="block title-bold text-4xl text-center mb-4">
          {APP_NAME}
          <span className="block font-primaryRegular text-sm mt-2 pb-4 text-gray-700">
            {APP_DESCRIPTION}
          </span>
        </Link>
        <form className="flex flex-col gap-2" onSubmit={(e) => submitForm(e)}>
          <div>
            <label className={inputLabelStyle}>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={inputStyle}
              disabled={isLoading}
              autoFocus
              required
            />
          </div>
          <div>
            <label className={inputLabelStyle}>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={inputStyle}
              disabled={isLoading}
              required
            />
          </div>
          {errMsg && (
            <div className="text-sm text-red-600 text-center">{errMsg}</div>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className={`block text-sm font-semibold p-3 rounded text-center ${
              isLoading
                ? 'bg-blue-300 cursor-wait'
                : 'bg-blue-700 hover:bg-blue-500 active:bg-blue-900 cursor-pointer shadow-md'
            }  text-slate-50 mt-4`}
          >
            {isLoading ? 'Please wait' : 'LOGIN'}
          </button>
        </form>
      </div>
      <div className="mt-6 text-sm text-gray-700">
        Login as truck account? Click{' '}
        <Link
          to="/auth/login"
          className="text-purple-700 hover:text-purple-500 active:text-purple-900 cursor-pointer"
        >
          here
        </Link>
      </div>
      <div>
        <div className='text-[11px] text-slate-900 text-center p-4 pt-8'>Supported by Healthy Oceans and Clean Cities Initiative implemented by UN-Habitat Philippines and funded by Government of Japan</div>
        <div className='text-center p-4'>
          <img src={KEY_PARTNERS} className='inline-block h-12 md:h-16 w-auto' alt="Key Partners of the Project" />
        </div>
      </div>
    </div>
  )
}
