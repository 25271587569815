type NumberFieldParams = {
  handleChange: (value: number) => void
  value?: string
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
  extClassName?: string
}

export const NumberField = ({
  handleChange,
  value = '',
  placeholder = '',
  label = '',
  required = false,
  disabled = false,
  extClassName = ''
}: NumberFieldParams) => {
  return (
    <div className="py-2">
      {label && (
        <label className="block text-sm text-gray-700 mb-2">{label}</label>
      )}
      <input
        type="number"
        placeholder={placeholder}
        className={`border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500 ${extClassName}`}
        value={value}
        onChange={(event) => handleChange(parseInt(event?.currentTarget.value))}
        required={required}
        disabled={disabled}
        min={0}
      />
    </div>
  )
}
