import { Link } from 'react-router-dom'
import { Account } from './account'
import BRAND_LOGO from './../../assets/images/swtrack-logo.png'

const Brand = () => {
  return <img src={BRAND_LOGO} className="h-8" alt="SWTrack Logo" />
}

export const TopBar = ({
  username,
  role
}: {
  username: string
  role: string
}) => {
  const isLoggedIn = username !== '' ? true : false

  return (
    <div className="w-full  shadow-md flex py-4 sticky top-0 z-40 bg-primary">
      <div className="container mx-auto px-4 flex justify-between items-center min-h-[45px]">
        <div className="inline-block">
          <Link to={role === 'admin' ? '/admin/' : '/'}>
            <Brand />
          </Link>
        </div>
        {isLoggedIn ? (
          <Account username={username} role={role} />
        ) : (
          <div>
            <Link
              to="/auth/signup"
              className="text-white hover:text-gray-50 active:text-gray-200 text-md mx-8"
            >
              Sign Up
            </Link>
            <Link
              to="/auth/login"
              className="text-blue-500 bg-white hover:bg-gray-50 active:bg-gray-200 text-sm font-semibold py-3 px-6 rounded text-center shadow-md "
            >
              LOGIN
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
