import { CallBackError, CallBackResponse, createAxios } from './createAxios'

const api = createAxios()

export const getTruckSchedules = async (
  dashboardType: string,
  scheduleFilter: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  // TODO for admin master, we should allow scheduleFilter=pastOnly
  // but not sure if we'll create a separate page for that
  const url = `/truck-schedules?scheduleFilter=${scheduleFilter}&dashboardType=${dashboardType}`
  api
    .get(url, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}