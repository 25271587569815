import { CallBackError, CallBackResponse, createAxios } from './createAxios'

const api = createAxios()

export const getPersonnels = async (
  query: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const url = `/personnels${query !== '' ? '?' + query : ''}`
  api
    .get(url, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createPersonnel = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/personnels', payload, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}