import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import { useState } from 'react'

const RoutePoint = ({ data }: any) => {
  const { completed, completedDate, name } = data

  return (
    <div className="grid grid-cols-5 w-full gap-4 py-2">
      <div className="self-center text-right">
        <CheckCircleIcon
          className={`w-8 h-8 inline-block ${
            completed ? 'text-green-500' : 'text-gray-300'
          }`}
        />
      </div>
      <div className="self-center truncate col-span-2">{name}</div>
      <div className="self-center truncate col-span-2">
        {completedDate ? moment(completedDate).format('LLL') : '-'}
      </div>
    </div>
  )
}

export const ScheduleCard = ({ data }: any) => {
  const [open, setOpen] = useState(false)
  const {
    scheduledDate,
    truck,
    fuelBefore,
    fuelAfter,
    route,
    wasteType,
    collectionStatus,
    routePoints,
    landfillCollectionDate,
    emergencyDetails,
    emergencyReportedDate
  } = data
  
  return (
    <>
      <div
        className="bg-gray-50 p-4 w-full grid grid-cols-7 gap-2 cursor-pointer hover:bg-gray-200 text-sm"
        onClick={() => setOpen(true)}
      >
        <div className="truncate">
          {moment(scheduledDate).format('MMMM DD, YYYY')}
        </div>
        <div className="truncate">{truck.name}</div>
        <div className="truncate">{route.name}</div>
        <div className="truncate">{fuelBefore ? fuelBefore : '-'}</div>
        <div className="truncate">{fuelAfter ? fuelAfter : '-'}</div>
        <div className="truncate">{wasteType}</div>
        <div className={`truncate ${
          collectionStatus === 'Complete'?
          'text-green-500 font-semibold':
          collectionStatus === 'Emergency' ?
            'text-red-500 font-semibold':
            'font-regular'
          }`
        }>{collectionStatus}</div>
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-40 opacity-80 "
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/2 min-h-screen z-50 bg-gray-50 p-10 flex flex-col duration-200  break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/2 shadow-none'
        }`}
      >
        <div className="flex justify-between">
          <div className="self-center text-lg font-bold">
            {moment(scheduledDate).format('MMMM DD, YYYY')}
          </div>
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-3">
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">ROUTE</div>
            <div className="text-md text-slate-900 break-words">
              {route.name}
            </div>
          </div>
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">TRUCK</div>
            <div className="text-md text-slate-900 break-words">
              {truck.name}
            </div>
          </div>
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">
              WASTE TYPE
            </div>
            <div className="text-md text-slate-900 break-words">
              {wasteType}
            </div>
          </div>
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">
              COLLECTION STATUS
            </div>
            <div
              className={`text-md font-semibold break-words ${
                collectionStatus === 'Complete'
                  ? 'text-green-500'
                  : collectionStatus === 'Emergency'
                    ? 'text-red-500'
                    : 'text-slate-900'
              }`}
            >
              {collectionStatus}
            </div>
          </div>
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">
              LANDFILL COLLECTION DATE
            </div>
            <div className="text-md text-slate-900 break-words">
              {landfillCollectionDate
                ? moment(landfillCollectionDate).format('LLL')
                : '-'}
            </div>
          </div>
        </div>

        <div>
          {routePoints.length !== 0 && (
            <>
              <div className="text-sm font-semibold text-gray-900">
                ROUTE POINTS
              </div>
              <div className="p-4">
                {routePoints.map((data: any, i: number) => {
                  return (
                    <div key={i}>
                      <RoutePoint data={data} />
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>

        <div className="grid grid-flow-row grid-cols-3 mt-8">
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">EMERGENCY DETAILS</div>
            <div className="text-md text-slate-900 break-words">
              {emergencyDetails? emergencyDetails:'-'}
            </div>
          </div>
          <div className="mb-8">
            <div className="text-sm font-semibold text-gray-900">EMERGENCY REPORTED DATE</div>
            <div className="text-md text-slate-900 break-words">
              {emergencyReportedDate? moment(emergencyReportedDate).format('LLL'):'-'}
            </div>
          </div>
        </div>

        {/* <div className="mb-4">
          <div className="text-sm text-gray-600 pt-8">
            Created at {moment(createdAt).format('MMMM Do YYYY, hh:mm A')}
          </div>
        </div> */}
      </div>
    </>
  )
}
