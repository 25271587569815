// import { TextField } from '../../../components/forms/textfield'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getRoutes } from '../../../api/routes'
import { RouteCard } from '../../../components/cards/route-card'
import { useSelector } from 'react-redux'

export const RoutesPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  // const [query, setQuery] = useState('')
  const [routes, setRoutes] = useState([])
  const [isReady, setIsReady] = useState(false)

  // const handleOnChange = (q: string) => {
  //   setQuery(q)
  // }

  const refetch = (queryParam: string = '') => {
    setIsReady(false)
    try {
      getRoutes(
        queryParam,
        loggedInUser.tokens,
        (response) => {
          setRoutes(response.data)
          setIsReady(true)
        },
        (error) => {
          console.log(error)
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
      setIsReady(true)
    }
  }

  useEffect(() => {
    refetch()
  }, [loggedInUser])

  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex p-4 align-middle self-center">
          <span className="self-center text-2xl font-bold">Routes</span>
          <Link to="/admin/routes/create">
            <button
              type="button"
              className="self-center ml-4 bg-orange-500 hover:bg-orange-400 active:bg-orange-600 active:shadow-none text-white text-sm p-2 rounded-md shadow-md inline-block"
            >
              <span className="self-center inline-block">CREATE</span>
            </button>
          </Link>
        </div>
        {/* <div className="self-center w-1/3 p-4">
          <TextField
            handleChange={(q: string) => handleOnChange(q)}
            value={query}
            placeholder="Find truck"
          />
        </div> */}
      </div>

      <div className="p-4 flex flex-col gap-2">
        {!isReady ? (
          <div>Loading ..</div>
        ) : routes.length === 0 ? (
          <div>No records found</div>
        ) : (
          routes.map((route, i) => {
            return (
              <div key={i} className="flex">
                <span className="self-center inline-block w-[30px] text-sm">
                  {i + 1}
                </span>
                <RouteCard route={route} />
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
