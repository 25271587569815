import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTruckSchedules } from '../../api/truck-schedule'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Tab } from '@headlessui/react'

const ScheduleCard = ({ data }: any) => {
  const { id, scheduledDate, truck, wasteType } = data

  return (
    <Link to={`/landfill/schedule/${id}`}>
      <div className="grid grid-cols-3 gap-2 px-4 py-2 bg-transparent hover:bg-gray-200 active:bg-gray-300 cursor-pointer ">
        <div>{moment(scheduledDate).format('MMMM DD, YYYY')}</div>
        <div>{truck.name}</div>
        <div>{wasteType}</div>
      </div>
    </Link>
  )
}

export const LandfillDashboard = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)
  const categoryKeys = ['today', 'upcoming', 'past'];
  const [categories, setCategories] = useState<any>({
    today: [],
    upcoming: [],
    past: [],
  })

  useEffect(() => {
    try {
      categoryKeys.forEach(key => {
        getTruckSchedules(
          'landfill',
          `${key}Only`,
          loggedInUser.tokens,
          (response) => {
            setCategories((prevValue: any) => {
              return {
                ...prevValue,
                [key]: response.data
              }
            })
            setIsReady(true)
          },
          (error) => {
            console.log(error)
            setIsReady(true)
          }
        )
      })
    } catch (error) {
      console.error(error)
    }

    setIsReady(true)
  }, [loggedInUser])
  
  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="flex justify-center">
      <div className="w-full md:w-3/4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row w-full p-4 align-middle justify-between self-center">
                <span className="self-center block text-2xl font-bold">
                  Truck Schedules
                </span>
                <span className="self-center block text-2xl font-bold">
                  {moment(new Date()).format('MMMM DD, YYYY')}
                </span>
              </div>
            </div>

            <div className="p-4 flex flex-col gap-2">
              {!isReady ? (
                <div className="text-center text-sm text-gray-500">Loading ..</div>
              ) : (
                <>
                  <div className="my-4">
                  <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                        {Object.keys(categories).map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                  ? 'bg-white shadow'
                                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="mt-4">
                        {Object.values(categories).map((schedules: any, idx) => (
                          <Tab.Panel
                            key={idx}
                            className={classNames(
                              'rounded-xl bg-white p-3',
                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                          >
                            <div className="p-4 w-full grid grid-cols-3 gap-2 text-xs border-b border-gray-300 mb-2">
                              <div>DATE</div>
                              <div>TRUCK</div>
                              <div>TYPE OF WASTE</div>
                            </div>
                            <div className="flex flex-col gap-2">
                              {schedules.map((sched: any, i: number) => {
                                return <ScheduleCard key={i} data={sched} />
                              })}
                            </div>
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </>
              )}
            </div>
          </div>
    </div>
  )
}
