import { ArrowLeftIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { TextField } from '../../../components/forms/textfield'
import { FormEvent, useCallback, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createRoute } from '../../../api/routes'
import { useSelector } from 'react-redux'

export const CreateRoutePage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [routePoints, setRoutePoints] = useState<string[]>([])
  const [newRoutePoint, setNewRoutePoint] = useState('')

  const initForm = () => {
    setName('')
    setNewRoutePoint('')
    setRoutePoints([])
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    console.log("submit");
    

    createRoute(
      {
        name: name,
        routePoints: routePoints
      },
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully created a route!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        initForm()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to create a route. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        //   setErrMsg('Something went wrong. Please try again later.')
        setIsLoading(false)
      }
    )
  }

  const addRoutePoint = () => {
    if (newRoutePoint) {
      setRoutePoints([...routePoints, newRoutePoint])
      setNewRoutePoint('')
    }
  }

  const removeRoutePoint = useCallback(
    (index: number) => {
      setRoutePoints((routePoints) =>
        routePoints.filter((_, idx) => {
          return idx !== index
        })
      )
    },
    [setRoutePoints]
  )

  return (
    <>
      <div className="p-4 w-full">
        <Link to="/admin/routes">
          <button
            type="button"
            className="self-center bg-transparent hover:bg-gray-200 active:bg-gray-300 active:shadow-none text-gray-900 text-sm p-2 rounded-md inline-block"
          >
            <ArrowLeftIcon className="w-5 h-5 inline-block" />
            <span className="self-center inline-block ml-4">ROUTES LIST</span>
          </button>
        </Link>
        <div className="self-center p-4 text-2xl font-bold">Create Route</div>
        <form
          className="px-4 grid gap-4 w-1/2"
          onSubmit={(e: FormEvent) => submitForm(e)}
        >
          <TextField
            handleChange={(q: string) => setName(q)}
            value={name}
            disabled={isLoading}
            label="Name"
            required
          />
          <div>
            <label className="block text-sm text-gray-700 mb-2">
              Route Points
            </label>
            <div className="flex">
              <div className="inline-block">
                <TextField
                  handleChange={(q: string) => setNewRoutePoint(q)}
                  value={newRoutePoint}
                  disabled={isLoading}
                  placeholder="Type here to add"
                />
              </div>
              <button
                type="button"
                onClick={() => addRoutePoint()}
                className={`self-center ml-4 ${
                  !!newRoutePoint
                    ? 'bg-orange-500 hover:bg-orange-400 active:bg-orange-600 shadow-md active:shadow-none'
                    : 'bg-orange-200'
                }  text-white text-sm p-2 rounded-md inline-block`}
                disabled={newRoutePoint === ''}
              >
                <PlusIcon className="w-4 h-4" />
              </button>
            </div>
            {routePoints.length !== 0 && (
              <div className="px-4">
                {routePoints.map((rp, i) => {
                  return (
                    <div key={i} className="flex py-2 group-item">
                      {/* <MapPinIcon className="self-center inline-block mr-2 w-4 h-4" />{' '} */}
                      <XMarkIcon
                        onClick={() => removeRoutePoint(i)}
                        className="self-center inline-block mx-4 w-5 h-5 rounded-sm cursor-pointer text-red-500 hover:bg-gray-200 active:bg-gray-300"
                      />
                      <span className="text-md">{rp}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`block text-sm font-semibold p-3 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
              }  text-slate-50 mt-4 w-full`}
            >
              {isLoading ? 'Please wait' : 'SUBMIT'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
