import { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Link } from 'react-router-dom'
import { getTruckById } from '../../api/truck'
import PLACEHOLDER_IMG from './../../assets/images/placeholder.png'
import moment from 'moment'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

type TruckInfo = {
  id: string
  role: string
  username: string
  truckId: string
}

type AccessTokenT = {
  type: string
  sub: TruckInfo
}

const ScheduleCard = ({ data }: any) => {
  const { scheduledDate, route } = data

  return (
    <Link to={`/schedule/${data.id}`}>
      <div className="flex gap-2 border rounded-md border-gray-300 p-4 bg-transparent hover:bg-gray-200 active:bg-gray-300">
        <div className="grid grid-cols-2 md:grid-cols-3 w-full">
          <div>{moment(scheduledDate).format('MMMM DD, YYYY')}</div>
          <div>{route.name}</div>
          <div className="hidden md:block text-right self-center">
            <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
          </div>
        </div>
      </div>
    </Link>
  )
}

export const TruckDashboard = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)
  const [schedules, setSchedules] = useState<any[]>([])
  const [name, setName] = useState('')
  const [plateNo, setPlateNo] = useState('')
  const [username, setUsername] = useState('')
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    try {
      const data: AccessTokenT = jwt_decode(loggedInUser.tokens.access.token)
      const truckId = data.sub.truckId

      getTruckById(
        truckId,
        loggedInUser.tokens,
        (response) => {
          setName(response.data.name ? response.data.name : '-')
          setImgSrc(response.data.imgSrc)
          setPlateNo(response.data.plateNo)
          setUsername(response.data.user.username)

          if (response.data.truckSchedules) {
            setSchedules(response.data.truckSchedules)
          }

          setIsReady(true)
        },
        (error) => {
          console.log(error)
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
    }

    setIsReady(true)
  }, [loggedInUser])

  const dateToday = moment(new Date()).format('MM/DD/YYYY')

  return (
    <div className="flex items-start flex-col md:flex-row w-full gap-4">
      <div className="w-full md:w-1/3 bg-slate-50 rounded-md p-4">
        <div className="text-lg text-slate-900 font-bold text-center">
          Truck Details
        </div>
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <div className="my-4">
            <div
              className="w-100 h-[250px] bg-cover bg-center rounded-lg mb-4"
              style={{
                backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`
              }}
            ></div>
            <div className="py-2">
              <div className="text-xs text-gray-600">TRUCK</div>
              <div className="text-lg font-semibold text-slate-900 break-words">
                {name}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600">PLATE NO.</div>
              <div className="text-lg font-semibold text-slate-900 break-words">
                {plateNo}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600">CODE</div>
              <div className="text-lg font-semibold text-slate-900 break-words">
                {username}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-2/3 bg-slate-50 rounded-md p-4 min-h-[400px] pb-8">
        <div className="text-lg text-slate-900 font-bold text-center">
          Schedule
        </div>
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <>
            <div className="my-4">
              <div className="font-bold text-lg">TODAY</div>
              <div className="mt-4">
                {schedules.length === 0 ? (
                  <div className="text-gray-500 text-sm">-</div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 md:grid-cols-3 w-full px-4 pb-2">
                      <div>Date</div>
                      <div>Route</div>
                    </div>
                    <div className="flex flex-col gap-2">
                      {schedules.map((sched, i) => {
                        if (
                          moment(sched.scheduledDate).format('MM/DD/YYYY') ===
                          dateToday
                        ) {
                          return <ScheduleCard key={i} data={sched} />
                        } else {
                          return null
                        }
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="my-4 mt-8">
              <div className="font-bold text-lg">UPCOMING</div>
              <div className="mt-4">
                {schedules.length === 0 ? (
                  <div className="text-gray-500 text-sm">-</div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 md:grid-cols-3 w-full px-4 pb-2">
                      <div>Date</div>
                      <div>Route</div>
                    </div>
                    <div className="flex flex-col gap-2">
                      {schedules.map((sched, i) => {
                        if (
                          moment(sched.scheduledDate).format('MM/DD/YYYY') !==
                          dateToday
                        ) {
                          return <ScheduleCard key={i} data={sched} />
                        } else {
                          return null
                        }
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
