import { CallBackError, CallBackResponse, createAxios } from './createAxios'

const api = createAxios()

export const getRoutes = async (
  query: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const url = `/routes?includeRoutePoints=true`
  api
    .get(url, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createRoute = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/routes', payload, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}
