import { useEffect, useState } from "react"
import { getTruckSchedules } from "../../../api/truck-schedule"
import moment from 'moment'
import { AdminLandfillCard } from "../../../components/cards/admin-landfill-card"
import { useSelector } from "react-redux"
import { Tab } from "@headlessui/react"
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { CSVLink } from 'react-csv'

export const AdminLandfillPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('today')
  const categoryKeys = ['today', 'upcoming', 'past'];
  const [categories, setCategories] = useState<any>({
    today: [],
    upcoming: [],
    past: [],
  })
  const [csvReport, setCsvReport] = useState<any>({
    headers: [
      { label: 'DATE', key: 'scheduledDate' },
      { label: 'TRUCK', key: 'truckName' },
      { label: 'TIME OF COLLECTION', key: 'collectionTime' },
      { label: 'TYPE OF WASTE', key: 'wasteType' },
      { label: 'WEIGHT', key: 'weight' },
    ],
    data: [],
    filename: 'landfills.csv',
  })

  const refetch = () => {
    setIsReady(false)
    try {
      categoryKeys.forEach(key => {
        getTruckSchedules(
          'admin-landfill',
          `${key}Only`,
          loggedInUser.tokens,
          (response) => {
            setCategories((prevValue: any) => {
              return {
                ...prevValue,
                [key]: response.data
              }
            })
            setIsReady(true)
          },
          (error) => {
            console.log(error)
            setIsReady(true)
          }
        )
      })
    } catch (error) {
      console.error(error)
      setIsReady(true)
    }
  }

  useEffect(() => {
    refetch()
  }, [loggedInUser])
  
  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    console.log(selectedCategory)
    const data = categories[selectedCategory].map(({
      scheduledDate,
      truck,
      landfillCollectionDate,
      wasteType,
      weight,
    }: any) => {
      return {
        scheduledDate: moment(scheduledDate).format('MMMM DD, YYYY'),
        truckName: truck.name,
        collectionTime: landfillCollectionDate ? moment(landfillCollectionDate).format('LLL') : '',
        wasteType,
        weight,
      }
    })

    setCsvReport((prevValue: any) => {
      return {
        ...prevValue,
        data,
      }
    })
  }, [selectedCategory])


  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row w-full p-4 align-middle justify-between self-center">
          <span className="self-center block text-2xl font-bold">
            Landfills
            <CSVLink {...csvReport}><ArrowDownTrayIcon className="h-8 w-8 cursor-pointer inline-block" /></CSVLink>
          </span>
          <span className="self-center block text-2xl font-bold">
            {moment(new Date()).format('MMMM DD, YYYY')}
          </span>
        </div>
      </div>

      <div className="p-4 flex flex-col gap-2">
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <>
            <div className="my-4">
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                  {Object.keys(categories).map((category) => (
                    <Tab
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                          selected
                            ? 'bg-white shadow'
                            : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                        )
                      }
                    >
                      {category}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="mt-4">
                  {Object.values(categories).map((schedules: any, idx) => (
                    <Tab.Panel
                      key={idx}
                      className={classNames(
                        'rounded-xl bg-white p-3',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                      )}
                    >
                      <div className="p-4 w-full grid grid-cols-5 gap-2 text-xs border-b border-gray-300">
                        <div>DATE</div>
                        <div>TRUCK</div>
                        <div>TIME OF COLLECTION</div>
                        <div>TYPE OF WASTE</div>
                        <div>WEIGHT</div>
                      </div>
                      <div className="flex flex-col gap-2">
                        {schedules.map((sched: any, i: number) => {
                          return (
                            <div key={i}>
                              <AdminLandfillCard data={sched} />
                            </div>
                          )
                        })}
                      </div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
