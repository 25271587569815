import { Outlet } from 'react-router-dom'
import { TopBar } from '../../components/topbar'
import { useSelector } from 'react-redux'

export const LandfillPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)

  return loggedInUser ? (
    <div className="min-w-full min-h-screen bg-gray-200">
    <TopBar username={loggedInUser.user.username} role={loggedInUser.user.role} />
      <div className="container p-4 mx-auto">
        <Outlet />
      </div>
    </div>
  ) : (
    <></>
  )
}
