import { FormEvent, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  getTruckScheduleBySchedId,
  updateTruckScheduleBySchedId
} from '../../api/truck'
import moment from 'moment'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NumberField } from '../../components/forms/number-field'
import { TimePicker } from '../../components/forms/time-picker'
import { useSelector } from 'react-redux'

export const LandfillSchedule = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)

  const [drivers, setDrivers] = useState<any[]>([])
  const [collectors, setCollectors] = useState<any[]>([])
  const [scheduledDate, setScheduledDate] = useState('')
  const [landfillCollectionDate, setLandfillCollectionDate] = useState('')
  const [wasteType, setWasteType] = useState('')
  const [weight, setWeight] = useState<any>(0)

  const { id = '' } = useParams()

  useEffect(() => {
    try {
      getTruckScheduleBySchedId(
        id,
        'includePersonnels=all',
        loggedInUser.tokens,
        (response) => {
          setDrivers(response.data.drivers)
          setCollectors(response.data.collectors)
          setScheduledDate(response.data.scheduledDate)
          setWasteType(response.data.wasteType)
          setLandfillCollectionDate(response.data.landfillCollectionDate && moment(response.data.landfillCollectionDate).format("HH:mm"))
          setWeight(response.data.weight);

          setIsReady(true)
        },
        (error) => {
          console.log(error)
          toast.error(
            'Failed to fetch schedule details. Please try again later.',
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          )
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Please refresh the page.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }

    setIsReady(true)
  }, [loggedInUser, id])

  const submitForm = (e: FormEvent) => {
    e.preventDefault()

    updateTruckScheduleBySchedId(
      id,
      {
        landfillCollectionDate: moment(landfillCollectionDate, "HH:mm"),
        weight,
      },
      loggedInUser.tokens,
      (response) => {
        console.log(response)

        toast.success('Successfully submitted collection details', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      },
      (error) => {
        console.log(error)
        toast.error(
          'Failed to submit collection details. Please try again later.',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
      }
    )
  }

  return (
    <div className="flex items-start flex-col md:flex-row w-full gap-4">
      <div className="w-full md:w-1/3 bg-slate-50 rounded-md p-4">
        <Link to="/">
          <button
            type="button"
            className="self-center bg-transparent hover:bg-gray-200 active:bg-gray-300 active:shadow-none text-gray-900 text-sm p-2 rounded-md inline-block"
          >
            <ArrowLeftIcon className="w-5 h-5 inline-block" />
            <span className="self-center inline-block ml-4">ALL SCHEDULES</span>
          </button>
        </Link>
        <div className="text-lg text-slate-900 font-bold py-4 text-center">
          Schedule Details
        </div>
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <div className="m-4 px-2">
            <div className="py-2">
              <div className="text-xs text-gray-600">DATE</div>
              <div className="text-lg text-slate-900 break-words">
                {scheduledDate
                  ? moment(scheduledDate).format('MMMM DD, YYYY')
                  : '-'}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600">WASTE TYPE</div>
              <div className="text-lg text-slate-900 break-words">
                {wasteType ? wasteType : '-'}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600 mb-1">DRIVERS</div>
              {drivers.length === 0 ? (
                <div>-</div>
              ) : (
                drivers.map((driver, i) => {
                  return (
                    <div
                      className="pl-4 text-lg text-slate-900 break-words p"
                      key={i}
                    >
                      - {driver.personnel.fullName}
                    </div>
                  )
                })
              )}
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600 mb-1">COLLECTORS</div>
              {collectors.length === 0 ? (
                <div>-</div>
              ) : (
                collectors.map((collector, i) => {
                  return (
                    <div
                      className="pl-4 text-lg text-slate-900 break-words p"
                      key={i}
                    >
                      - {collector.personnel.fullName}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-2/3 bg-slate-50 rounded-md p-4 min-h-[400px] pb-8">
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <div className="py-4">
            <form onSubmit={submitForm}>
              <div className="text-xl text-slate-900 font-bold text-center pb-4">
                {moment(scheduledDate).format('MMMM DD, YYYY')}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-4 py-2 px-8">
                  <div className="w-1/6 text-md font-semibold text-slate-900">
                    Waste Type
                  </div>
                  <div className="w-5/6 text-md text-slate-900 break-words">
                    {wasteType}
                  </div>
                </div>
                <div className="flex flex-row gap-4 py-2 px-8 ">
                  <div className="w-1/6 self-center text-md font-semibold text-slate-900">
                    Time Collected
                  </div>
                  <div className="w-1/2 text-md text-slate-900 break-words">
                    <TimePicker
                      handleChange={(q: string) => setLandfillCollectionDate(q)}
                      value={landfillCollectionDate}
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-4 py-2 px-8 ">
                  <div className="w-1/6 self-center text-md font-semibold text-slate-900">
                    Weight
                  </div>
                  <div className="w-1/2 text-md text-slate-900 break-words">
                    <NumberField
                      handleChange={(n: number) => setWeight(n)}
                      value={weight}
                      extClassName=""
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-4 py-2 px-8 ">
                  <div className="w-1/6 self-center text-md font-semibold text-slate-900"></div>
                  <div className="w-5/6 text-md text-slate-900 break-words">
                    <button
                      type="submit"
                      disabled={!isReady}
                      className={`block text-sm font-semibold p-3 rounded text-center ${
                        !isReady
                          ? 'bg-blue-300 cursor-wait'
                          : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
                      }  text-slate-50 mt-4 w-full md:w-1/2 `}
                    >
                      {!isReady ? 'Please wait' : 'SUBMIT'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}
