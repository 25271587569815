import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import { useState } from 'react'

type RoutePoint = {
  id: string
  name: string
  ordinalNo: number
}

export type RouteT = {
  id: string
  name: string
  createdAt: string
  routePoints: RoutePoint[]
}

export type RouteCardProps = {
  route: RouteT
}

export const RouteCard = ({ route }: RouteCardProps) => {
  const [open, setOpen] = useState(false)
  const { name, routePoints, createdAt } = route

  routePoints.sort((a,b) => a.ordinalNo - b.ordinalNo);

  return (
    <>
      <div
        className="bg-gray-50 p-4 w-full flex justify-between rounded-md cursor-pointer hover:bg-gray-200 border border-gray-300"
        onClick={() => setOpen(true)}
      >
        <span className="inline-block self-center">{name}</span>
        <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-40 opacity-80 "
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 min-h-screen z-50 bg-gray-50 p-10 flex flex-col duration-200  break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/3 shadow-none'
        }`}
      >
        <div className="text-right">
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="text-left flex-row gap-4">
          <div className="mb-8">
            <div className="text-sm text-gray-600">ROUTE</div>
            <div className="text-3xl font-bold text-slate-900 break-words">
              {name}
            </div>
          </div>
          {routePoints.length !== 0? (
            <>
              <div className='text-sm text-gray-600'>START</div>
              <div className="pl-8">
                {routePoints.map((rp, i) => {
                  return (
                    <div key={i} className='flex my-2'>
                      <MapPinIcon className="self-center w-4 h-4 inline-block mr-4" /> <span className='self-center inline-block'>{rp.name}</span>
                    </div>
                  )
                })}
              </div>
              <div className='text-sm text-gray-600'>END</div>
            </>
          ):(
            <div className='text-sm text-gray-600'>No routes points</div>
          )}
          <div className="mb-4">
            <div className="text-sm text-gray-600 pt-8">Created at {moment(createdAt).format('MMMM Do YYYY, hh:mm A')}</div>
          </div>
        </div>
      </div>
    </>
  )
}
