import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  completeTruckScheduleRoutePoint,
  deleteTruckScheduleRoutePoint,
  getTruckScheduleBySchedId,
  updateTruckScheduleBySchedId
} from '../../api/truck'
import moment from 'moment'
import { ArrowLeftIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NumberField } from '../../components/forms/number-field'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { TextField } from '../../components/forms/textfield'
import { useSelector } from 'react-redux'

const RoutePointCard = ({ data, changeStatus }: any) => {
  const { name, completed, completedDate } = data

  return (
    <div
      className="flex border rounded-md border-gray-300 p-4 bg-transparent hover:bg-gray-200 active:bg-gray-300 cursor-pointer"
      onClick={() => changeStatus()}
    >
      <div className="self-center mr-4">
        <CheckCircleIcon
          className={`inline-block self-center w-8 h-8 ${
            completed ? 'text-green-500' : 'text-gray-300'
          }`}
        />
      </div>
      <div className="self-center">{name}</div>
      {/* TODO fix alignment of completed time */}
      {
        completedDate && <div className="justify-right">{moment(completedDate).format('hh:mm a')}</div>
      }
    </div>
  )
}

export const TruckSchedule = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)

  const [drivers, setDrivers] = useState<any[]>([])
  const [collectors, setCollectors] = useState<any[]>([])
  const [routeName, setRouteName] = useState('')
  const [scheduledDate, setScheduledDate] = useState('')
  const [wasteType, setWasteType] = useState('')
  const [fuelBefore, setFuelBefore] = useState<any>(0)
  const [fuelAfter, setFuelAfter] = useState<any>(0)
  const [routePoints, setRoutePoints] = useState<any[]>([])
  const [emergencyDetails, setEmergencyDetails] = useState('')
  const [emergencyReportedDate, setEmergencyReportedDate] = useState('')

  const { id = '' } = useParams()

  const fetchData = useCallback(() => {
    getTruckScheduleBySchedId(
      id,
      'includePersonnels=all',
      loggedInUser.tokens,
      (response) => {
        setDrivers(response.data.drivers)
        setCollectors(response.data.collectors)
        setRouteName(response.data.route.name)
        setScheduledDate(response.data.scheduledDate)
        setWasteType(response.data.wasteType)
        setRoutePoints(response.data.routePoints)
        setFuelBefore(response.data.fuelBefore || 0)
        setFuelAfter(response.data.fuelAfter || 0)
        setEmergencyDetails(response.data.emergencyDetails)
        setEmergencyReportedDate(response.data.emergencyReportedDate)

        setIsReady(true)
      },
      (error) => {
        console.log(error)
        toast.error(
          'Failed to fetch schedule details. Please try again later.',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
        setIsReady(true)
      }
    )
  }, [id])

  useEffect(() => {
    try {
      fetchData()
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Please refresh the page.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }, [fetchData, loggedInUser])

  const updateRoutePoint = (routePointId: string, status: boolean) => {
    if (status) {
      completeTruckScheduleRoutePoint(
        {
          truckScheduleId: id,
          routePointId: routePointId
        },
        loggedInUser.tokens,
        (response) => {
          toast.success('Successfully updated route point to done', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })

          setRoutePoints(oldRoutePoints => {
            return oldRoutePoints.map(rp => {
              if (rp.id === routePointId) {
                return {
                  ...rp,
                  completed: true,
                  completedDate: new Date(),
                }
              } else {
                return rp;
              }
            })
          })
        },
        (error) => {
          console.log(error)
          toast.error(
            'Failed to update route point status. Please try again later.',
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          )
        }
      )
    } else {
      deleteTruckScheduleRoutePoint(
        id,
        routePointId,
        loggedInUser.tokens,
        (response) => {
          toast.success('Successfully updated route point to not done', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })

          setRoutePoints(oldRoutePoints => {
            return oldRoutePoints.map(rp => {
              if (rp.id === routePointId) {
                return {
                  ...rp,
                  completed: false,
                  completedDate: null,
                }
              } else {
                return rp;
              }
            })
          })
        },
        (error) => {
          console.log(error)
          toast.error(
            'Failed to update route point status. Please try again later.',
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          )
        }
      )
    }
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsReady(false)

    updateTruckScheduleBySchedId(
      id,
      {
        fuelBefore,
        fuelAfter
      },
      loggedInUser.tokens,
      (response) => {
        if (response.status === 200) {
          toast.success('Successfully updated fuel details', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        }
        setIsReady(true)
      },
      (error) => {
        console.log(error)
        toast.error(
          'Failed to send fuel details. Please try again later.',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
        setIsReady(true)
      }
    )
  }

  const submitEmergency = (e: FormEvent) => {
    e.preventDefault()
    const dateToday = moment(new Date());
    updateTruckScheduleBySchedId(
      id,
      {
        emergencyDetails,
        emergencyReportedDate: dateToday
      },
      loggedInUser.tokens,
      (response) => {
        if (response.status === 200) {
          toast.success('Successfully sent emergency details', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })

          setEmergencyReportedDate(dateToday.toString());
        }
      },
      (error) => {
        console.log(error)
        toast.error(
          'Failed to send emergency details. Please try again later.',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
      }
    )
  }

  const handleFuelChange = (n: number, fuelTime: string) => {
    if (n || n === 0) {
      if (fuelTime === 'before') {
        setFuelBefore(n)
      } else {
        setFuelAfter(n)
      }
    }
  }

  return (
    <div className="flex items-start flex-col md:flex-row w-full gap-4">
      <div className="w-full md:w-1/3 bg-slate-50 rounded-md p-4">
        <Link to="/">
          <button
            type="button"
            className="self-center bg-transparent hover:bg-gray-200 active:bg-gray-300 active:shadow-none text-gray-900 text-sm p-2 rounded-md inline-block"
          >
            <ArrowLeftIcon className="w-5 h-5 inline-block" />
            <span className="self-center inline-block ml-4">ALL SCHEDULES</span>
          </button>
        </Link>
        <div className="text-lg text-slate-900 font-bold py-4 text-center">
          Schedule Details
        </div>
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <div className="m-4 px-2">
            <div className="py-2">
              <div className="text-xs text-gray-600">DATE</div>
              <div className="text-lg text-slate-900 break-words">
                {scheduledDate
                  ? moment(scheduledDate).format('MMMM DD, YYYY')
                  : '-'}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600">ROUTE</div>
              <div className="text-lg text-slate-900 break-words">
                {routeName}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600">WASTE TYPE</div>
              <div className="text-lg text-slate-900 break-words">
                {wasteType ? wasteType : '-'}
              </div>
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600 mb-1">DRIVERS</div>
              {drivers.length === 0 ? (
                <div>-</div>
              ) : (
                drivers.map((driver, i) => {
                  return (
                    <div
                      className="pl-4 text-lg text-slate-900 break-words p"
                      key={i}
                    >
                      - {driver.personnel.fullName}
                    </div>
                  )
                })
              )}
            </div>
            <div className="py-2">
              <div className="text-xs text-gray-600 mb-1">COLLECTORS</div>
              {collectors.length === 0 ? (
                <div>-</div>
              ) : (
                collectors.map((collector, i) => {
                  return (
                    <div
                      className="pl-4 text-lg text-slate-900 break-words p"
                      key={i}
                    >
                      - {collector.personnel.fullName}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-2/3 bg-slate-50 rounded-md p-4 min-h-[400px] pb-8">
        {!isReady ? (
          <div className="text-center text-sm text-gray-500">Loading ..</div>
        ) : (
          <div className="py-4">
            <div className="text-xl text-slate-900 font-bold text-center pb-4">
              {moment(scheduledDate).format('MMMM DD, YYYY')}
            </div>
            <div className="flex flex-col gap-2">
              <form
                onSubmit={(e: FormEvent) => submitForm(e)}
              >
                <div className="flex flex-row gap-4 py-2 px-8">
                  <div className="w-1/6 text-md font-semibold text-slate-900">
                    Route
                  </div>
                  <div className="w-5/6 text-md text-slate-900 break-words">
                    {routeName}
                  </div>
                </div>
                <div className="flex flex-row gap-4 py-2 px-8 ">
                  <div className="w-1/6 self-center text-md font-semibold text-slate-900">
                    Fuel Before
                  </div>
                  <div className="w-5/6 text-md text-slate-900 break-words">
                    <NumberField
                      handleChange={(n: number) => handleFuelChange(n, 'before')}
                      value={fuelBefore}
                      extClassName=""
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-4 py-2 px-8 ">
                  <div className="w-1/6 self-center text-md font-semibold text-slate-900">
                    Fuel After
                  </div>
                  <div className="w-5/6 text-md text-slate-900 break-words">
                    <NumberField
                      handleChange={(n: number) => handleFuelChange(n, 'after')}
                      value={fuelAfter}
                      extClassName=""
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-4 py-2 px-8">
                  <button
                    type="submit"
                    disabled={!isReady}
                    className={`block text-sm font-semibold p-3 rounded text-center ${
                      !isReady
                        ? 'bg-blue-300 cursor-wait'
                        : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
                    }  text-slate-50 mt-4 w-full`}
                  >
                    {!isReady ? 'Please wait' : 'SUBMIT'}
                  </button>
                </div>
              </form>
              <div className="w-full py-4 text-center">
                <div className="inline-block border-b-2 border-gray-300 w-5/6"></div>
              </div>
              <div className="flex flex-row gap-4 py-2 px-8">
                <div className="w-1/6 text-md font-semibold text-slate-900">
                  Route Points
                </div>
                <div className="w-5/6 text-md text-slate-900 break-words">
                  {routePoints.length === 0 ? (
                    <div>-</div>
                  ) : (
                    <div className="flex flex-col gap-2">
                      {routePoints.map((routePoint, i) => {
                        return (
                          <div className="w-full" key={i}>
                            <RoutePointCard
                              data={routePoint}
                              changeStatus={() =>
                                updateRoutePoint(
                                  routePoint.id,
                                  !routePoint.completed
                                )
                              }
                            />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full py-4 text-center">
                <div className="inline-block border-b-2 border-gray-300 w-5/6"></div>
              </div>
              <div className="flex flex-col py-4 text-center w-full">
                <span className="text-slate-900 text-center pb-4">
                  <div className="text-lg font-bold">Emergency</div>
                </span>
                <form
                  className="flex w-full justify-center"
                  onSubmit={(e: FormEvent) => submitEmergency(e)}
                >
                  <div className="w-4/6 md:w-1/2 lg:w-1/3">
                    <TextField
                      handleChange={(q: string) => setEmergencyDetails(q)}
                      value={emergencyDetails}
                      placeholder="Enter emergency details here"
                    />
                    <span className="block text-sm text-gray-700 mb-2">
                    {emergencyReportedDate
                      ? `Sent: ${moment(emergencyReportedDate).format('MMM DD hh:mm a')}`
                      : '-'}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="rounded-md ml-4 py-2 px-4 self-center text-white bg-red-500 hover:bg-red-400 active:bg-red-700"
                  >
                    <PaperAirplaneIcon className="w-8 h-8 " />
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}
