import { useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

type MultiPickerBoxParams = {
  options: string[]
  handleChange: (value: string[]) => void
  selected: string[]
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
}

export const MultiPickerBox = ({
  options = [],
  handleChange,
  selected = [],
  placeholder = '',
  label = '',
  required = false,
  disabled = false
}: MultiPickerBoxParams) => {
  const [query, setQuery] = useState('')
  const [internalOptions, setInternalOptions] = useState<string[]>([])

  useEffect(() => {
    setInternalOptions(
      options.filter((op: string) => {
        return !selected.includes(op)
      })
    )
  }, [options, selected])

  const handleSelect = (v: string) => {
    if (selected.includes(v)) {
      handleChange(
        selected.filter((s) => {
          return s !== v
        })
      )
    } else {
      handleChange([...selected, v])
    }
    setQuery('')
  }

  const handleRemove = (index: number) => {
    handleChange(
      selected.filter((_, i) => {
        return i !== index
      })
    )
  }

  const filteredOptions =
    query === ''
      ? internalOptions
      : internalOptions.filter((option) =>
          option
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="py-2">
      <Combobox disabled={disabled}>
        <div className="relative">
          <div className="relative w-full">
            {label && (
              <label className="block text-sm text-gray-700 mb-2">
                {label}
              </label>
            )}
            <Combobox.Input
              className="border-2 w-full text-slate-900 text-sm rounded-md p-3 pr-8 border-gray-300 hover:border-blue-400 focus:border-blue-500"
              onChange={(event) => setQuery(event.target.value)}
              value={query}
              placeholder={placeholder}
              required={required}
            />
            <Combobox.Button className="absolute bottom-3.5 right-0 pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Combobox.Options className="absolute border-2 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:outline-none border-gray-300">
            {filteredOptions.length === 0? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-500">
                Nothing found
              </div>
            ) : (
              filteredOptions.map((option, i) => (
                <Combobox.Option
                  key={`${option}_${i}`}
                  className={({ active, selected }) =>
                    `relative w-full cursor-pointer select-none py-2 pl-10 pr-4 bg-transparent text-slate-900 hover:bg-blue-500 hover:text-white ${
                      active ? 'bg-blue-500' : 'bg-transparent'
                    } ${selected ? 'bg-blue-200' : 'bg-white'}`
                  }
                  value={option}
                  onClick={() => handleSelect(option)}
                >
                  <span className="text-sm">{option}</span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
      {selected.length !== 0 && (
        <div className="pl-8 pt-2 pb-4">
          {selected.map((option: string, i: number) => {
            return (
              <div key={i} className='my-2 flex'>
                <XMarkIcon
                  onClick={() => handleRemove(i)}
                  className="self-center inline-block mx-4 w-5 h-5 rounded-sm cursor-pointer text-red-500 hover:bg-gray-200 active:bg-gray-300"
                />
                <span className='text-md text-slate-900'>{option}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
