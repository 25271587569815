import { XMarkIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import { useState } from 'react'

export const AdminLandfillCard = ({ data }: any) => {
  const [open, setOpen] = useState(false)
  const {
    scheduledDate,
    truck,
    landfillCollectionDate,
    wasteType,
    weight,
  } = data
  
  return (
    <>
      <div
        className="bg-gray-50 p-4 w-full grid grid-cols-5 gap-2 cursor-pointer hover:bg-gray-200 text-sm"
        onClick={() => setOpen(true)}
      >
        <div className="truncate">
          {moment(scheduledDate).format('MMMM DD, YYYY')}
        </div>
        <div className="truncate">{truck.name}</div>
        <div className="truncate">{ landfillCollectionDate ? moment(landfillCollectionDate).format('LLL') : '-' }</div>
        <div className="truncate">{wasteType}</div>
        <div className="truncate">{weight}</div>
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-40 opacity-80 "
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/4 min-h-screen z-50 bg-gray-50 p-10 flex flex-col duration-200  break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/2 shadow-none'
        }`}
      >
        <div className="flex justify-between">
          <div className="self-center text-lg font-bold">
            {moment(scheduledDate).format('MMMM DD, YYYY')}
          </div>
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="mb-8">
          <div className="text-sm font-semibold text-gray-900">TRUCK</div>
          <div className="text-md text-slate-900 break-words">
            {truck.name}
          </div>
        </div>
        <div className="mb-8">
          <div className="text-sm font-semibold text-gray-900">
            LANDFILL COLLECTION DATE
          </div>
          <div className="text-md text-slate-900 break-words">
            {landfillCollectionDate
              ? moment(landfillCollectionDate).format('LLL')
              : '-'}
          </div>
        </div>
        
        <div className="mb-8">
          <div className="text-sm font-semibold text-gray-900">
            WASTE TYPE
          </div>
          <div className="text-md text-slate-900 break-words">
            {wasteType}
          </div>
        </div>

        
        
        <div className="mb-8">
          <div className="text-sm font-semibold text-gray-900">
            WEIGHT
          </div>
          <div className="text-md text-slate-900 break-words">
            {weight}
          </div>
        </div>
      </div>
    </>
  )
}
