import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useState } from 'react'

export type PersonnelT = {
  id: string
  fullName: string
  contactNumber: string
  createdAt: string
  type: string
}

export type PersonnelCardProps = {
  personnel: PersonnelT
}

export const PersonnelCard = ({ personnel }: PersonnelCardProps) => {
  const [open, setOpen] = useState(false)
  const { fullName, type, contactNumber, createdAt } = personnel

  return (
    <>
      <div
        className="bg-gray-50 p-4 w-full flex justify-between rounded-md cursor-pointer hover:bg-gray-200 border border-gray-300"
        onClick={() => setOpen(true)}
      >
        <span className="inline-block self-center">{fullName}</span>
        <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-40 opacity-80 "
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 min-h-screen z-50 bg-gray-50 p-10 flex flex-col duration-200 break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/3 shadow-none'
        }`}
      >
        <div className="text-right">
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="text-left flex-row gap-4">
          <div className="mb-8">
            <div className="text-sm text-gray-600">FULL NAME</div>
            <div className="text-3xl font-bold text-slate-900 break-words">
              {fullName}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600">CONTACT NUMBER</div>
            <div className="text-xl text-slate-900">{contactNumber? contactNumber:"-"}</div>
          </div>
          <div className="mb-4">
            <div className="text-sm text-gray-600">TYPE</div>
            <div className="text-xl text-slate-900">{type}</div>
          </div>
          
          <div className="mb-4">
            <div className="text-sm text-gray-600 pt-8">Created at {moment(createdAt).format('MMMM Do YYYY, hh:mm A')}</div>
          </div>
        </div>
      </div>
    </>
  )
}
