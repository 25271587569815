// import { TextField } from '../../../components/forms/textfield'
import { Link } from 'react-router-dom'
// import { getRoutes } from '../../../api/routes'

export const SchedulesPage = () => {
  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex p-4 align-middle self-center">
          <span className="self-center text-2xl font-bold">Schedules</span>
          <Link to="/admin/schedules/create">
            <button
              type="button"
              className="self-center ml-4 bg-orange-500 hover:bg-orange-400 active:bg-orange-600 active:shadow-none text-white text-sm p-2 rounded-md shadow-md inline-block"
            >
              <span className="self-center inline-block">CREATE</span>
            </button>
          </Link>
        </div>
        {/* <div className="self-center w-1/3 p-4">
          <TextField
            handleChange={(q: string) => handleOnChange(q)}
            value={query}
            placeholder="Find truck"
          />
        </div> */}
      </div>

      <div className="p-4 flex flex-col gap-2">
        <div>No records found</div>
      </div>
    </div>
  )
}
