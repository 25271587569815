import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { LoginPage } from './pages/auth/login'
// import { SignUpPage } from './pages/auth/signup'
import { AuthPage } from './pages/auth'
import { MainPage } from './pages/main'
import { DashboardPage } from './pages/main/dashboard'

import { NotFoundPage } from './pages/error/404'
import { TrucksPage } from './pages/main/truck'
import { CreateTruckPage } from './pages/main/truck/create-truck'
import { RoutesPage } from './pages/main/routes'
import { CreateRoutePage } from './pages/main/routes/create-route'
import { AdminLandfillPage } from './pages/main/admin-landfill'
import { SchedulesPage } from './pages/main/schedule'
import { CreateSchedulePage } from './pages/main/schedule/create-schedule'
import { PersonnelsPage } from './pages/main/personnel'
import { CreatePersonnelPage } from './pages/main/personnel/create-personnel'
import { TruckLogin } from './pages/truck/login'
import { TruckSchedule } from './pages/truck/schedule'
import { TruckDashboard } from './pages/truck/dashboard'
import { TruckPage } from './pages/truck'
import { LandfillDashboard } from './pages/landfill/dashboard'
import { LandfillPage } from './pages/landfill'
import { LandfillSchedule } from './pages/landfill/schedule'
import { Protected } from './components/Protected'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <Routes>

        {/* Auth Routes */}
        <Route path='/auth' element={ <AuthPage /> }>
          <Route path='/auth/login/admin' element={ <LoginPage /> } />
          <Route path='/auth/login' element={ <TruckLogin /> } />
        </Route>

        {/* Admin Routes */}
        <Route path='/admin' element={ <Protected expectedRole="admin"> <MainPage /> </Protected> }>
          <Route path='/admin/routes/create' element={ <CreateRoutePage /> } />
          <Route path='/admin/routes' element={ <RoutesPage /> } />
          <Route path='/admin/trucks/create' element={ <CreateTruckPage /> } />
          <Route path='/admin/trucks' element={ <TrucksPage /> } />
          <Route path='/admin/personnels/create' element={ <CreatePersonnelPage /> } />
          <Route path='/admin/personnels' element={ <PersonnelsPage /> } />
          <Route path='/admin/landfills' element={ <AdminLandfillPage /> } />
          <Route path='/admin/schedules/create' element={ <CreateSchedulePage /> } />
          <Route path='/admin/schedules' element={ <SchedulesPage /> } />
          <Route index path='/admin' element={ <DashboardPage /> } />
        </Route>

        {/* Landfill Routes */}
        <Route path='/landfill' element={ <Protected expectedRole="landfill"> <LandfillPage /> </Protected> }>
          <Route path='/landfill/schedule/:id' element={ <LandfillSchedule /> } />
          <Route index path='/landfill' element={ <LandfillDashboard /> } />
        </Route>

        {/* Truck Routes */}
        <Route path='/' element={ <Protected expectedRole="truck"> <TruckPage /> </Protected> }>
          <Route path='/schedule/:id' element={ <TruckSchedule /> } />
          <Route index path='/' element={ <TruckDashboard /> } />
        </Route>        

        {/* Error Pages */}
        <Route path='/404' element={ <NotFoundPage /> } /> 
        <Route path="*" element={ <Navigate to="/404" /> } />
        
      </Routes>
    </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
