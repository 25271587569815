import { Navigate, Outlet, useOutlet } from 'react-router-dom'

export const AuthPage = () => {
  const outlet = useOutlet()

  return (
    outlet ? (
      <div className="min-h-screen min-w-full bg-slate-200">
        <div className="container min-h-screen px-4 mx-auto">
          <Outlet />
        </div>
      </div>
    ) : (
      <Navigate to="/404" />
    )
  )
}
