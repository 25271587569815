import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { TextField } from '../../../components/forms/textfield'
import { FormEvent, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createPersonnel } from '../../../api/personnel'
import { ComboBox } from '../../../components/forms/combo-box'
import { useSelector } from 'react-redux'

const personnelTypeOptions = ['driver', 'collector']

export const CreatePersonnelPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [fullName, setFullName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [contactNumber, setContactNumber] = useState('')
  const [personnelType, setPersonnelType] = useState('')

  const initForm = () => {
    setFullName('')
    setContactNumber('')
    setPersonnelType('')
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    createPersonnel(
      {
        fullName: fullName,
        contactNumber: contactNumber,
        type: personnelType
      },
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully created a manpower!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        initForm()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to create a manpower. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        //   setErrMsg('Something went wrong. Please try again later.')
        setIsLoading(false)
      }
    )
  }

  return (
    <>
      <div className="p-4 w-full">
        <Link to="/admin/personnels">
          <button
            type="button"
            className="self-center bg-transparent hover:bg-gray-200 active:bg-gray-300 active:shadow-none text-gray-900 text-sm p-2 rounded-md inline-block"
          >
            <ArrowLeftIcon className="w-5 h-5 inline-block" />
            <span className="self-center inline-block ml-4">MANPOWER LIST</span>
          </button>
        </Link>
        <div className="self-center p-4 text-2xl font-bold">
          Create Manpower
        </div>
        <form
          className="px-4 grid gap-4 w-1/2"
          onSubmit={(e: FormEvent) => submitForm(e)}
        >
          <TextField
            handleChange={(q: string) => setFullName(q)}
            value={fullName}
            disabled={isLoading}
            label="Full Name"
            required
          />
          <TextField
            handleChange={(q: string) => setContactNumber(q)}
            value={contactNumber}
            disabled={isLoading}
            label="Contact Number"
            required
          />
          <ComboBox
            label="Manpower Type"
            options={personnelTypeOptions}
            placeholder="Select type"
            handleChange={setPersonnelType}
            value={personnelType}
            required
          />

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`block text-sm font-semibold p-3 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
              }  text-slate-50 mt-4 w-full`}
            >
              {isLoading ? 'Please wait' : 'SUBMIT'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
