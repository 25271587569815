// import { TextField } from '../../../components/forms/textfield'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPersonnels } from '../../../api/personnel'
import {
  PersonnelCard,
  PersonnelT
} from '../../../components/cards/personnel-card'
import { useSelector } from 'react-redux'

export const PersonnelsPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [drivers, setDrivers] = useState<PersonnelT[]>([])
  const [collectors, setCollectors] = useState<PersonnelT[]>([])
  const [isReady, setIsReady] = useState(false)

  // const handleOnChange = (q: string) => {
  //   setQuery(q)
  // }

  const refetch = (queryParam: string = '') => {
    setIsReady(false)
    try {
      getPersonnels(
        queryParam,
        loggedInUser.tokens,
        (response) => {
          setDrivers(
            response.data.filter((personnel: PersonnelT) => {
              return personnel.type === 'driver'
            })
          )
          setCollectors(
            response.data.filter((personnel: PersonnelT) => {
              return personnel.type === 'collector'
            })
          )
          setIsReady(true)
        },
        (error) => {
          console.log(error)
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
      setIsReady(true)
    }
  }

  useEffect(() => {
    refetch()
  }, [loggedInUser])

  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex p-4 align-middle self-center">
          <span className="self-center text-2xl font-bold">Manpower</span>
          <Link to="/admin/personnels/create">
            <button
              type="button"
              className="self-center ml-4 bg-orange-500 hover:bg-orange-400 active:bg-orange-600 active:shadow-none text-white text-sm p-2 rounded-md shadow-md inline-block"
            >
              <span className="self-center inline-block">CREATE</span>
            </button>
          </Link>
        </div>
      </div>

      <div className="p-4 grid grid-cols-2 gap-8">
        {!isReady ? (
          <div>Loading ..</div>
        ) : (
          <>
            <div>
              <div className="text-sm font-bold mb-4">DRIVERS</div>
              <div>
                {drivers.length === 0 ? (
                  <div>No records found</div>
                ) : (
                  <div>
                    {drivers.map((driver, i) => {
                      return (
                        <div key={i} className="flex my-2">
                          <span className="self-center inline-block w-[30px] text-sm">
                            {i + 1}
                          </span>
                          <PersonnelCard personnel={driver} />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="text-sm font-bold mb-4">COLLECTORS</div>
              <div>
                {collectors.length === 0 ? (
                  <div>No records found</div>
                ) : (
                  <div>
                    {collectors.map((collector, i) => {
                      return (
                        <div key={i} className="flex my-2">
                          <span className="self-center inline-block w-[30px] text-sm">
                            {i + 1}
                          </span>
                          <PersonnelCard personnel={collector} />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
