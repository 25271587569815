import { NavLink, Outlet } from 'react-router-dom'
import { TopBar } from '../../components/topbar'
import {
  HomeModernIcon,
  MapIcon,
  PresentationChartLineIcon,
  TruckIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

const linkStyle = ({ isActive }: { isActive: boolean }) =>
  `block p-3 text-sm cursor-pointer ${
    isActive
      ? 'bg-primary text-white'
      : 'bg-transparent hover:bg-slate-300 active:bg-slate-400'
  }`

export const MainPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)

  return loggedInUser ? (
    <div className="min-w-full min-h-screen bg-gray-200">
      <TopBar username={loggedInUser.user.username} role={loggedInUser.user.role} />
      <div className="container p-4 mx-auto">
        <div className="flex flex-row w-100 gap-4">
          <div className="w-[250px] flex flex-col justify-center gap-4 fixed">
            <NavLink to="/admin/schedules/create">
              <button
                type="button"
                className="block p-3 text-sm font-semibold shadow-lg active:shadow-none text-white cursor-pointer bg-blue-500 hover:bg-blue-300 active:bg-blue-400 w-full rounded-md"
              >
                CREATE SCHEDULE
              </button>
            </NavLink>
            {/* <div className="border-b border-gray-400 w-4/5 self-center"></div> */}
            <div>
              <div className="bg-slate-50 rounded overflow-hidden border">
                <NavLink
                  to="/admin/"
                  className={linkStyle}
                  style={{ borderBottomWidth: '1px' }}
                >
                  <PresentationChartLineIcon className="inline-block w-5 h-5 mr-2 -mt-1" />
                  Dashboard
                </NavLink>
                <NavLink to="/admin/landfills" className={linkStyle}>
                  <HomeModernIcon className="inline-block w-5 h-5 mr-2 -mt-1" />{' '}
                  Landfills
                </NavLink>
              </div>
            </div>
            <div>
              <div className="bg-slate-50 rounded overflow-hidden border">
                <NavLink
                  to="/admin/trucks"
                  className={linkStyle}
                  style={{ borderBottomWidth: '1px' }}
                >
                  <TruckIcon className="inline-block w-5 h-5 mr-2 -mt-1" />{' '}
                  Trucks
                </NavLink>
                <NavLink
                  to="/admin/routes"
                  className={linkStyle}
                  style={{ borderBottomWidth: '1px' }}
                >
                  <MapIcon className="inline-block w-5 h-5 mr-2 -mt-1" /> Routes
                </NavLink>
                <NavLink to="/admin/personnels" className={linkStyle}>
                  <UserGroupIcon className="inline-block w-5 h-5 mr-2 -mt-1" />{' '}
                  Manpower
                </NavLink>
              </div>
            </div>
          </div>
          <div className="ml-[250px] w-full">
            <div className="ml-4 flex bg-slate-50 rounded min-h-screen">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div className=' bg-gray-700 text-center mt-8 text-[11px]'>
        <span className='inline-block text-white p-4'><b className='inline-block text-white pr-8'>SWTrack v.1.0.0</b> Developed by <a href="https://www.studiovawter.com" className='border-b font-semibold border-transparent hover:border-white' rel="noreferrer" target='_blank'>Studio Vawter</a></span>
      </div>
    </div>
  ) : (
    <></>
  )
}
